<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- Brand logo-->
          <!--<b-link class="brand-logo"> -->
          <img
            class="logo"
            src="@/assets/images/celcomLogo/logo-checkout-01.svg"
            alt="Celcom Logo"
          />
          <!--</b-link> -->
          <!-- /Brand logo-->

          <div class="clearfix"></div>

          <b-card-title title-tag="h2"> Iniciar Sesión </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!-- <small class="text-danger"> Ingrese email </small> -->
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password"></label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Contraseña"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!-- <small class="text-danger"> Ingrese contraseña </small> -->
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-row>
                  <b-col>
                    <b-form-checkbox
                      class="custom-control-secondary"
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                    >
                      <label>Recuérdame</label>
                    </b-form-checkbox>
                  </b-col>
                  <b-col class="text-right">
                    <b-link :to="{ name: 'auth-forgot-password-v2' }">
                      <small>Olvidé mi contraseña</small>
                    </b-link>
                  </b-col>
                </b-row>
              </b-form-group>

              <!-- submit buttons -->
              <router-link to="/dashboard/ecommerce">
                <b-button
                  type="submit"
                  class="btn-login shadow-none"
                  block
                >
                <!--@click="validationForm"  TODO: Esto va en el botón ingresar para validar-->
                  Ingresar
                </b-button>
              </router-link >
              <!-- submit buttons -->
              <a href="https://checkout.arpi.ar/doc/Documentacion.pdf" target="_blank">
              <b-button class="btn-docs" variant="outline-secondary" block>
                Ir a la Documentación
              </b-button>
              </a>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>

      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.btn-login {
  border-color: $primary !important;
  background-color: $primary !important;
  margin-top: 16%;
  margin-bottom: 4%;
}


.btn-docs {
  border-color: $secondary !important;
  color: $secondary !important;
}

h2 {
  display: block;
  text-align: center !important;
  /* font-family: Nunito Sans !important; */
  font-style: normal !important;
  font-weight: 700 !important;
  color: $dark !important;
}

.logo {
  display: block;
  width: 72%;
  margin: auto;
  margin-top: 12%;
  margin-bottom: 32%;
}

.clearfix {
  clear: both;
  float: none;
}

.remember-check {
  color: $secondary !important;
  font-size: small !important;
}

input[type="checkbox"] {
  background-color: $secondary !important;
  border-color: $secondary !important;
}

a {
  color: $dark !important;
}

label {
  color: $secondary !important;
}

.custom-control-label {
  color: $secondary !important;
}
</style>
